/* Gets the text to render crispy and most similar to Figma comps */
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smooth: never;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/figtree/Figtree-VariableFont_wght.ttf')
    format('truetype-variations');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Figtree';
  src: url('/fonts/figtree/Figtree-Italic-VariableFont_wght.ttf')
    format('truetype-variations');
  font-weight: 125 950;
  font-stretch: 75% 125%;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Mona Sans';
  src: url('/fonts/mona-sans/Mona-Sans.woff2')
      format('woff2 supports variations'),
    url('/fonts/mona-sans/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 700;
  font-stretch: 125%;
}

.web-navigation-container.web-navigation-container {
  position: fixed;
  top: 0px;
  z-index: 100;
  left: 0;
  right: 0;
}

.web-navigation.web-navigation {
  transition: background-color 0.2s ease;
  z-index: 100;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}

.web-navigation-blur.web-navigation-blur {
  backdrop-filter: blur(12px);
}

.web-switcher-item {
  transform: skew(-20deg);
}

.web-switcher-item-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.responsive-iframe {
  max-width: 100%;
  padding-top: 56.25%;
  position: relative;
  width: 100%;
}

.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Zendesk chat styles for web. Native is not possible with them. */
.wizard-styles iframe[title='Button to launch messaging window'] {
  z-index: 10 !important;
  bottom: 85px !important;
}
.wizard-styles iframe[title='Number of unread messages'] {
  z-index: 11 !important;
  bottom: 133px !important;
}

iframe[title='Button to launch messaging window'] {
  z-index: 10 !important;
}
iframe[title='Number of unread messages'] {
  z-index: 11 !important;
}

@media screen and (min-width: 1020px) {
  .wizard-styles iframe[title='Button to launch messaging window'] {
    z-index: 10 !important;
    bottom: 135px !important;
  }
  .wizard-styles iframe[title='Number of unread messages'] {
    z-index: 11 !important;
    bottom: 183px !important;
  }
}

.feedback-record-button {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(252, 129, 129, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(252, 129, 129, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(252, 129, 129, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(252, 129, 129, 0);
  }
}


.zsiq_theme1.zsiq_floatmain {
  margin-bottom: 80px;
}